import { useMutation } from '@apollo/client'
import FooterUI, { Props } from '@interflora/ui-components/build/components/Footer/Footer'
import React from 'react'
import { populateTemplateValues } from 'utils/components'
import { HandleSubmit, NewsletterSignUpValues } from '../../../../ui-components/build/common/props'
import { NEWSLETTER_SIGNUP } from '../../graphql/queries'
import _get from 'lodash/get'

type FooterProps = Omit<Props, 'onSubmit' & 'signUpConfirm'> & { newsLetterSignUp: any }

const updateSignUpTemplate: NewsletterSignUpValues = {
  firstName: '',
  lastName: '',
  email: '',
}

const Footer = (props: FooterProps) => {
  const [signUpMutation] = useMutation(NEWSLETTER_SIGNUP)
  const signUp: HandleSubmit<NewsletterSignUpValues> = async (values) => {
    try {
      const signUpValues = { ...values, email: values.email.trim() }
      const signUpNewsletterValues = populateTemplateValues(signUpValues, updateSignUpTemplate)
      await signUpMutation({ variables: { input: signUpNewsletterValues } })
    } catch (error) {
      const errMsg = _get(error, ['graphQLErrors', 0, 'message'])
      throw new Error(errMsg)
    }
  }
  const { newsLetterSignUp, ...others } = props

  return <FooterUI signUp={signUp} newsletterSignUp={newsLetterSignUp} {...others} />
}

export default Footer
